
import { mapGetters } from 'vuex'
import dashboardMenu from '@/mixins/dashboardMenu'

export default {
    mixins: [dashboardMenu],

    computed: {
        ...mapGetters({ breakHeader: 'data/breakHeader' }),
    },
    
    created() {
        this.setVisibility('videoGuide', false)
        this.setVisibility('submissions', false)
    }
}
