export default {
    props: {
        icon: {
            type: String,
            default: undefined,
        },
        prepend: Boolean,
    },
    data() {
        return {
            clickable: false,
        }
    },
    mounted() {
        this.clickable = !!this.$listeners.click
    },
}
