
import posseMenu from '@/mixins/user/posseMenu'
import dashboardMenu from '@/mixins/dashboardMenu'

export default {
    mixins: [posseMenu, dashboardMenu],
    created() {
        this.setName('dashboard', 'Dashboard')
        this.setName('moodBoards', 'Boards')
    }
}
