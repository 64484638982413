export default {
    props: {
        defaultOpen: Boolean,
        sticky: Boolean,
        disabled: Boolean,
        auto: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isOpen: this.defaultOpen,
            watchRoute: true,
        }
    },

    mounted() {
        if (this.watchRoute) {
            this.$watch('$route', () => {
                if (!this.sticky) this.isOpen = false
            })
        }
    },

    methods: {
        open() {
            if (!this.disabled) {
                this.isOpen = true
                this.$emit('open')
            }
        },
        close() {
            if (!this.disabled) {
                this.isOpen = false
                this.$emit('close')
            }
        },
        toggle() {
            if (!this.disabled) {
                this.isOpen = !this.isOpen
                this.$emit(this.isOpen ? 'open' : 'close')
            }
        },
        clickOutside(e) {
            if (this.auto && (e.target?.attributes?.for?.value !== this.$attrs['aria-ref'] || !this.$attrs['aria-ref'])) this.close()
        },
    },
}
