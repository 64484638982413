
import capitalize from '@/mixins/capitalize'
import { mapState } from 'vuex'
import auth from '@/mixins/forms/auth'

export default {

    mixins: [auth, capitalize],

    data() {
        return {
            signInVisible: true,
            loading: false,
            isOpen: false,
            model: {
                email: null,
                password: null
            },
        }
    },
   
    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
            taxonomies: (state) => state.data.taxonomies || [],
            selects: (state) => state.jsonloader.data['selects.json'] || {},
        }),

    
    },

    mounted(){
        this.$nuxt.$on('open-login-modal', () => {
            this.isOpen = true
        })  
    },
    
    methods: {
        async loginPassword() {
            try {
                if (this.$gtag?.event) this.$gtag.event('login', { method: 'Google', event_label: 'modal' })
                this.loading = true
                await this.$store.dispatch('user/loginPassword', { username: this.model.email, password: this.model.password })
                this.$trackConnect()
                this.$trackEvent('LOGIN')
                this.isOpen = false
            } catch (e) {
                // eslint-disable-next-line no-console
                console.error(`Error during sign in.`, e)
            } finally {
                this.loading = false
            }
        },
        async register() {
            try {
                this.loading = true
                await this.$store.dispatch('user/register', { ...this.model, is_vendor: this.isVendor })

                if (this.$route.query.email) {
                    const onboarding = this.$cookies.get('onboarding') || {}
                    onboarding.coreg = true
                    this.$cookies.set('onboarding', onboarding)
                }
                this.$store.dispatch('klaviyo/event', 'loverly_organic_user')
                this.initApp(this.user)
                this.$track('Register')
                this.$trackEvent('REGISTER')
            } catch {
                // eslint-disable-next-line no-console
                console.error(`Error during sign up.`)
            } finally {
                this.loading = false
            }
        },
        signUp(){
            this.signInVisible = false
        },
        signIn(){
            this.signInVisible = true
        },
    },
}
