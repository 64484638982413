
export default {
    inheritAttrs: false,
    props: {
        value: {
            type: String,
            default: undefined,
        },
    },

    data() {
        return {
            type: 'password',
        }
    },

    computed: {
        icon() {
            return this.type === 'password' ? 'eye' : 'eye-slash'
        },
    },

    methods: {
        toggleType() {
            this.type = this.type === 'password' ? 'text' : 'password'
        },
    },
}
