
import { mapMutations } from 'vuex'

export default {
    props: {
        value: Boolean,
        scroll: Boolean,
        full:{
            type: Boolean,
            default: true,
        },
        closeable: {
            type: Boolean,
            default: true,
        },
        contentClass: {
            type: String,
            default: 'gap-4 p-4',
        },
    },

    computed: {
        smDevice() {
            return this.$store.getters['data/smDevice']
        },
    },

    watch: {
        value(val) {
            if (val) {
                document.querySelector('body').classList.add('modal-open')
            } else {
                document.querySelector('body').classList.remove('modal-open')
                this.setConfirmationData({})
            }
        },
    },

    methods: {
        ...mapMutations({
            setConfirmationData: 'confirmation/setConfirmationData',
        }),
        close() {
            if (this.closeable) this.$emit('input', false)
        },
    },
}
