import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind.config'
const fullConfig = resolveConfig(tailwindConfig)

class Resolution {
    constructor(tailwindConfig, store) {
        const XS_BREAKPOINT_MAX = 'xs-max'
        const SM_DEVICE_BREAKPOINT_MAX = 'md-max'
        const HEADER_BREAKPOINT_MAX = 'md-max'
        this._isSmallDevice = false
        this._store = store
        this._tailwindConfig = tailwindConfig
        this._xsBreakpointMax = this.getBreakpoint(XS_BREAKPOINT_MAX) || 991
        this._smDeviceBreakpointMax = this.getBreakpoint(SM_DEVICE_BREAKPOINT_MAX) || 991
        this._headerBreakpointMax = 1260
    }

    get xsBreakpointMax() {
        return this._xsBreakpointMax
    }

    get smDeviceBreakpointMax() {
        return this._smDeviceBreakpointMax
    }

    get headerBreakpointMax() {
        return this._headerBreakpointMax
    }

    init = () => {
        this._store.dispatch('data/setDeviceSize', window.innerWidth <= this._smDeviceBreakpointMax)
        this._store.dispatch('data/setBreakHeader', window.innerWidth <= this._headerBreakpointMax)
        if (window.innerWidth <= this._xsBreakpointMax) this._store.dispatch('data/setResolution', ['xs', true])
    }

    getBreakpoint = (breakpointString) => {
        const breakpoint = this._tailwindConfig.theme.screens[breakpointString]?.max || this._tailwindConfig.theme.screens[breakpointString]
        if (typeof breakpoint === 'string') return parseInt(breakpoint.replaceAll('px', ''))
        return breakpoint
    }

    deviceSizeChange = (e) => {
        this._store.dispatch('data/setDeviceSize', e.matches)
    }

    breakHeaderChange = (e) => {
        this._store.dispatch('data/setBreakHeader', e.matches)
    }

    resolutionSizeChange = (e, resolution) => {
        this._store.dispatch('data/setResolution', [resolution, e.matches])
    }
}

export default function ({ store }) {
    const resolution = new Resolution(fullConfig, store)
    const deviceSize = window.matchMedia(`(max-width: ${resolution.smDeviceBreakpointMax}px)`)
    const breakHeader = window.matchMedia(`(max-width: ${resolution.headerBreakpointMax}px)`)
    const xsResolution = window.matchMedia(`(min-width: 0px) and (max-width: ${resolution.xsBreakpointMax}px)`)

    deviceSize.addEventListener('change', resolution.deviceSizeChange)
    breakHeader.addEventListener('change', resolution.breakHeaderChange)
    xsResolution.addEventListener('change', (e) => resolution.resolutionSizeChange(e, 'xs'))
    resolution.init()
}
