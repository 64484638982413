import { mapState } from 'vuex'

export default {
    data() {
        return {
            userMenuContent: {
                dashboard: {
                    name: 'Home',
                    visible: true,
                },
                checklist: {
                    name: 'Checklist',
                    visible: true,
                },
                guestList: {
                    name: 'Guests',
                    visible: true,
                },
                videoGuide: {
                    name: 'Videos & Guides',
                    visible: true,
                },
                vendorManager: {
                    name: 'Vendor Manager',
                    visible: true,
                },
                submissions: {
                    name: 'My Submissions',
                    visible: true,
                },
                moodBoards: {
                    name: 'Saved',
                    visible: true,
                },
                registry: {
                    name: 'Registry',
                    visible: true,
                },
            },
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.auth.user || {},
        }),
        isVendor() {
            return !!this.user?.is_vendor
        },
        onVendorDashboard() {
            return this.$route.name != 'tools-vendor-manager' && this.$route.name.includes('vendor-')
        },
        masterclassEnabled() {
            return this.$config.masterclassEnabled
        },

        menu() {
            return this.isVendor && this.onVendorDashboard
                ? [
                      { name: 'Dashboard', key: 'dashboard', icon: 'sliders', to: { name: 'vendor-dashboard' } },
                      { name: 'My Tasks', key: 'tasks', icon: 'check-circle', to: { name: 'vendor-checklist' } },
                      { name: 'Profile', key: 'profile', icon: 'users', to: { name: 'vendor-management-step', params: { step: 1 } } },
                      { name: 'Submissions', key: 'submissions', icon: 'paper-plane', to: { name: 'tools-vendor-submit-wedding-my-weddings' } },
                  ]
                : [
                      ...(this.userMenuContent.dashboard.visible
                          ? [{ name: this.userMenuContent.dashboard.name, key: 'dashboard', icon: 'sliders', to: { name: 'dashboard' } }]
                          : []),
                      ...(this.userMenuContent.checklist.visible
                          ? [{ name: this.userMenuContent.checklist.name, key: 'checklist', icon: 'check-circle', to: { name: 'tools-wedding-checklist' } }]
                          : []),
                      ...(this.userMenuContent.guestList.visible
                          ? [{ name: this.userMenuContent.guestList.name, key: 'guest_list', icon: 'users', to: { name: 'tools-guest-list' } }]
                          : []),
                      ...(this.userMenuContent.videoGuide.visible && this.masterclassEnabled
                          ? [{ name: this.userMenuContent.videoGuide.name, key: 'videos_guides', icon: 'video', to: { name: 'tools-videos' } }]
                          : []),
                      ...(this.userMenuContent.vendorManager.visible
                          ? [{ name: this.userMenuContent.vendorManager.name, key: 'vendor_manager', icon: 'briefcase', to: { name: 'tools-vendor-manager' } }]
                          : []),
                      ...(this.userMenuContent.submissions.visible
                          ? [
                                {
                                    name: this.userMenuContent.submissions.name,
                                    key: 'submissions',
                                    icon: 'rings-wedding',
                                    to: { name: 'tools-submit-wedding-my-weddings' },
                                },
                            ]
                          : []),
                      ...(this.userMenuContent.moodBoards.visible
                          ? [
                                {
                                    name: this.userMenuContent.moodBoards.name,
                                    key: 'mood_boards',
                                    icon: 'heart',
                                    to: { name: 'users-username-boards', params: { username: this.user?.username } },
                                },
                            ]
                          : []),
                      ...(this.userMenuContent.registry.visible && this.$config.birdieEnabled && this.user?.wedding_details?.date
                          ? [
                                {
                                    name: this.userMenuContent.registry.name,
                                    key: 'registry',
                                    icon: 'gift',
                                    to: { name: 'tools-cash-registry' },
                                },
                            ]
                          : []),
                  ]
        },
    },
    methods: {
        setName(key, name) {
            this.$set(this.userMenuContent[key], 'name', name)
        },
        setVisibility(key, visibility) {
            this.$set(this.userMenuContent[key], 'visible', visibility)
        },
    },
}
