export default function ({ store, from, route }) {
    if (process.client) {
        if (from?.name !== route?.name && store.getters['layout/defaultState']) {
            store.dispatch('layout/setHeader')
            store.dispatch('layout/setBackground')
            store.dispatch('layout/setBackgroundImage')
            store.dispatch('layout/setMenu')
        }
    }
}
