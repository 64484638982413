import { render, staticRenderFns } from "./User.vue?vue&type=template&id=b24efc84&lang=pug"
import script from "./User.vue?vue&type=script&lang=js"
export * from "./User.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyV2Avatar: require('/app/components.v2/elements/Avatar.vue').default,LoverlyV2Button: require('/app/components.v2/elements/Button.vue').default,LoverlyV2Icon: require('/app/components.v2/elements/Icon.vue').default})
