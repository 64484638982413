import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=466d4cdc&scoped=true&lang=pug"
import script from "./PageHeader.vue?vue&type=script&lang=js"
export * from "./PageHeader.vue?vue&type=script&lang=js"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=466d4cdc&prod&lang=postcss&scoped=true"
import style1 from "./PageHeader.vue?vue&type=style&index=1&id=466d4cdc&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "466d4cdc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyLogo: require('/app/components/elements/Logo.vue').default,LoverlyIcon: require('/app/components/elements/typography/Icon.vue').default,LoverlyButton: require('/app/components/inputs/Button.vue').default,LoverlyMobileMenu: require('/app/components/menus/megamenu/MobileMenu.vue').default,LoverlyScrollObserver: require('/app/components/elements/ScrollObserver.vue').default,LoverlyMegaMenu: require('/app/components/menus/megamenu/MegaMenu.vue').default,LoverlyGlobalSearch: require('/app/components/search/GlobalSearch.vue').default,LoverlyUserMenu: require('/app/components/menus/UserMenu.vue').default,LoverlyMenuIcon: require('/app/components/menus/MenuIcon.vue').default,LoverlyLoginRegisterForm: require('/app/components/forms/LoginRegisterForm.vue').default,LoverlyModal: require('/app/components/elements/Modal.vue').default,LoverlyPaymentModal: require('/app/components/pages/payment/PaymentModal.vue').default,LoverlyHeading: require('/app/components/elements/typography/Heading.vue').default})
