export default {
    watch: {
        /*
        $auth(val) {
            if (process.browser && val.loggedIn) {
                try {
                    this.$store.dispatch('push/init', val.user)
                } catch (e) {}
            }
        },
        */
        $route: {
            handler() {
                if (process.browser) {
                    this.$nextTick(() => {
                        document.querySelector('body').classList.remove('modal-open')
                    })
                    this.$trackEvent('VISIT')
                }
            },
            immediate: true,
        },
    },

    async mounted() {
        this.$trackConnect()
        try {
            const workbox = await window.$workbox
            if (workbox) {
                workbox.addEventListener('installed', (event) => {
                    if (event.isUpdate) {
                        this.$toast
                            .info('Please refresh your browser to receive the latest update.', {
                                action: {
                                    text: 'Refresh',
                                    class: 'btn',
                                    onClick: (e, toast) => {
                                        toast.goAway(0)
                                        window.location.reload(true)
                                    },
                                },
                            })
                            .goAway(5000)
                    }
                })
            }
        } catch (e) {}
        if (process.browser) {
            /*
            if (this.$auth.loggedIn && this.$auth.user) {
                try {
                    this.$store.dispatch('push/init', this.$auth.user)
                } catch (e) {}
            }
            */
            window.addEventListener('online', this.updateNetworkStatus)
            window.addEventListener('offline', this.updateNetworkStatus)
            this.updateNetworkStatus()
        }
    },

    destroyed() {
        if (process.browser) {
            if (this.networkToast) this.networkToast.goAway()
            window.removeEventListener('online', this.updateNetworkStatus)
            window.removeEventListener('offline', this.updateNetworkStatus)
        }
    },

    methods: {
        updateNetworkStatus() {
            if (process.browser) {
                this.networkStatus = navigator.onLine ? 'online' : 'offline'
                if (this.networkToast) this.networkToast.goAway()
                if (this.networkStatus === 'offline') {
                    this.networkToast = this.$toast.error('Offline mode')
                } else if (this.networkToast) this.networkToast.goAway()
            }
        },
    },

    data() {
        return {
            networkStatus: 'online',
            networkToast: null,
        }
    },
}
