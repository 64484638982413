
import { mapState } from 'vuex'
export default {

    async fetch() {
        await this.$store.dispatch('jsonloader/fetchJson', `countdown.json`)
    },

    computed: {

        ...mapState({
            jsonData: (state) => state.jsonloader.data || {},
        }),

        json() {
            return this.jsonData['countdown.json'] || {
                enabled: false,
                expiry: '2025-01-20T23:59:59',
                batteryDays: 60,
                link: 'https://www.loverly.com',
                text: 'Plan your wedding like a pro.',
            }
        },

        countdownDate() {
            try {
                return new Date(this.json.expiry).getTime()
            } catch (e) {
                return new Date().getTime()
            }
        },

        days() {
            return Math.floor(this.distance / (1000 * 60 * 60 * 24))
        },

        hours() {
            return Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
        },

        minutes() {
            return Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
        },

        seconds() {
            return Math.floor((this.distance % (1000 * 60)) / 1000)
        },

        batteryLevel() {
            return (this.days / (this.json.batteryDays || 1)) * 100
        }
    },

    data() {
        return {
            distance: 0,
        }
    },

    methods: {
        update(dt) {
            this.distance = this.countdownDate - new Date().getTime()
            requestAnimationFrame(this.update)
        }
    },

    mounted() {
        this.update()
    }

}
