import { render, staticRenderFns } from "./Auth.vue?vue&type=template&id=48fe94af&lang=pug"
import script from "./Auth.vue?vue&type=script&lang=js"
export * from "./Auth.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoverlyV2Logo: require('/app/components.v2/elements/Logo.vue').default,LoverlyV2Heading: require('/app/components.v2/elements/Heading.vue').default,LoverlyV2InputLine: require('/app/components.v2/input/Line.vue').default,LoverlyV2InputPassword: require('/app/components.v2/input/Password.vue').default,LoverlyV2Button: require('/app/components.v2/elements/Button.vue').default,LoverlyV2Icon: require('/app/components.v2/elements/Icon.vue').default,LoverlyV2Agreement: require('/app/components.v2/elements/Agreement.vue').default,LoverlyV2Modal: require('/app/components.v2/elements/Modal.vue').default})
