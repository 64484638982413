import Vue from 'vue'

Vue.mixin({
    methods: {
        aKey() {
            let aKey = window.$nuxt.$cookies.get('a-key')
            if (!aKey) {
                aKey = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
                    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
                )
                window.$nuxt.$cookies.set('a-key', aKey)
            }
            return aKey
        },
        $track(eventName, eventData = {}) {
            if (!process.client) return
            const data = {
                name: eventName,
                extra: eventData || {},
                url: window.location.href,
                aKey: this.aKey(),
                facebook: {
                    fbp: window.$nuxt.$cookies.get('_fbp') || null,
                    fbc: window.$nuxt.$cookies.get('_fbc') || null,
                },
            }
            try {
                if (window.$nuxt && window.$nuxt.$fb && window.$nuxt.$fb.track) window.$nuxt.$fb.track(eventName, eventData)
            } catch (e) {}
            try {
                // eslint-disable-next-line no-console
                return window.$nuxt.$axios.post('/api/v1/t', data).catch((e) => console.warn('Failed: ', e))
            } catch (e) {
                return {}
            }
        },

        $trackEvent(eventName, eventData = {}) {
            if (!process.client) return
            // eslint-disable-next-line no-console
            try {
                return (
                    window.$nuxt.$axios
                        .post('/api/v1/e', { aKey: this.aKey(), extra: eventData, event: eventName, url: window.location.href, referer: document.referrer })
                        // eslint-disable-next-line no-console
                        .catch((e) => console.warn('Failed', e))
                )
            } catch (e) {
                return {}
            }
        },

        $trackEventBatch(eventName, eventData = {}) {
            if (!process.client) return
            if (eventName == 'IMPRESSION')
            {
                this.$eventPush(eventName, eventData)
            } else this.$trackEvent(eventName, eventData)
        },

        $eventPush(eventName, eventData = {}) {
            if (!process.client) return
            const event = { event: eventName, extra: eventData, url: window.location.href, aKey: this.aKey(), referer: document.referrer }
            if (window.batchEvents && window.batchEvents.push) window.batchEvents.push(event)
            else window.batchEvents = [event]
            if (window.batchTimer) clearTimeout(window.batchTimer)
            if (window.batchEvents.length >= 50) {
                window.$nuxt.$axios
                    .post('/api/v1/be', { events: window.batchEvents })
                    .catch((e) => console.warn('Failed sending Batch Events', e))
                window.batchEvents = []
            } else {
                window.batchTimer = setTimeout(() => {
                    window.$nuxt.$axios
                        .post('/api/v1/be', { events: window.batchEvents })
                        .catch((e) => console.warn('Failed sending Batch Events', e))
                    window.batchEvents = []
                }, 1000)
            }
        },

        $trackConnect() {
            if (process.browser) {
                try {
                    window.$nuxt.$echo.leave('a')
                    window.$nuxt.$echo.connector.options.auth.headers.AKey = this.aKey()
                    const token = window.$nuxt.$auth.strategy.token.get() || null
                    const tokenStatus = window.$nuxt.$auth.strategy.token.status()
                    if (window.$nuxt.$auth.loggedIn && token && tokenStatus.valid())
                        window.$nuxt.$echo.connector.options.auth.headers.Authorization = token
                    else delete window.$nuxt.$echo.connector.options.auth.headers.Authorization
                    window.$nuxt.$echo.connector.options.auth.headers.url = window.location.href
                    window.$nuxt.$echo.join('a')

                    if (window.$nuxt.$auth.loggedIn && window.$nuxt.$auth.user && window.$nuxt.$auth.user.id && window.$nuxt.$echo.connector.options.auth.headers.Authorization) {
                        window.$nuxt.$echo.private(`Loverly.Models.User.${window.$nuxt.$auth.user.id}`).listen('.updated', (e) => {
                            window.$nuxt.$auth.fetchUser()
                        })
                    }
                } catch (e) {}
            }
        },

        $eventValues(model = {}, map = {}) {
            const obj = {}
            Object.keys(map).forEach((x) => {
                if (model && model[map[x]]) obj[x] = model[map[x]]
                else obj[x] = map[x].split('.').reduce((p, c) => (p && p[c]) || null, model)
            })
            return obj
        },
    },
})
