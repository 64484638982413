
import siteMixin from '@/mixins/siteMixin'
import { mapGetters } from 'vuex'

export default {
    name: 'ContentLayout',

    mixins: [siteMixin],

    computed: {
        ...mapGetters({ title: 'layout/title',to: 'layout/to', background: 'layout/background', menu: 'layout/menu' }),
    },
    methods:{
        onClick(action, payload){
            this.$store.dispatch(action, payload)
        }
    }
}
